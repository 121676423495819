<template>
	<section class="container">
		<div class="row mt-3">
			<template v-if="$can('Add', 'Company', 'Group')">
				<div class="col-auto mt-1">
					<b-button variant="success" @click="addGroup">Opret ny gruppe</b-button>
				</div>
			</template>
		</div>
		<br />
		<b-card title="Grupper">
			<div v-if="groups && groups.length > 0" class="row">
				<div v-for="(group, index) in groups" :key="index" class="col-md-4 mb-4">
					<b-card class="insurance-card" @click="openGroup(group.id)">
						<b-card-header class="text-center">
							<h5>
								<b>{{ group.name }}</b>
							</h5>
						</b-card-header>
						<div class="card-body text-center">
							<b-card-text>
								<b>Antal medarbejdere: </b>
								{{ employeeCountWithThisGroup(group.id) }}
							</b-card-text>
							<b-card-text>
								<b>Tillægsløn er dækningsgivende: </b>
								<span :class="{ 'text-success': group.salaryIsBeneficial, 'text-danger': !group.salaryIsBeneficial }">
									{{ group.salaryIsBeneficial ? 'Ja' : 'Nej' }}
								</span>
							</b-card-text>
							<hr />
							<b-card-text>
								<b>Forsikringaftaler: </b>
								<span class="badge badge-info">{{ getGroupInsurances(group.id).length }}</span>
								<ul class="list-group mt-2">
									<li v-for="(insurance, index) in getGroupInsurances(group.id)" :key="index" class="list-group-item">
										<h6 class="mb-0">{{ insurance.insurance.name }}</h6>
									</li>
								</ul>
							</b-card-text>
							<hr />
							<b-card-text>
								<b>Pensionaftaler: </b>
								<span class="badge badge-info">{{ getGroupPensions(group.id).length }}</span>
								<ul class="list-group mt-2">
									<li v-for="(pension, index) in getGroupPensions(group.id)" :key="index" class="list-group-item">
										<h6 class="mb-0">{{ pension.pension.name }}</h6>
									</li>
								</ul>
							</b-card-text>
						</div>
						
					</b-card>
				</div>
			</div>

			<div v-else class="row justify-content-center">
				<div class="col-12 text-center mt-5">
					<h3 class="text-muted">Ingen grupper fundet</h3>
					<p>Start med at oprette din første gruppe.</p>
				</div>
			</div>
		</b-card>
	</section>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

export default {
	name: 'CompanyGroup',
	data() {
		return {
			globalfilter: '',
		}
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	methods: {
		employeeCountWithThisGroup(groupId) {
			return this.$store.getters['companyVuex/employeePolicies'].filter((policy) => policy.employeeCondition.group === groupId).length
		},
		deleteGroup(groupId) {
			this.$swal.fire({
				title: 'Er du sikker?',
				text: 'Gruppen vil blive slettet permanent',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ja, slet!',
				preConfirm: () => {
					if (this.employeeCountWithThisGroup(groupId) > 0) {
						this.$swal.showValidationMessage('Gruppen kan ikke slettes, da der er medarbejdere tilknyttet')
						return false
					} else {
						this.company.groups.splice(this.company.groups.indexOf(this.company.groups.find((group) => group.id == groupId)), 1)
						const data = this.company
						const id = this.companyId
						return this.$store
							.dispatch('companyVuex/updateCompany', { data, id })
							.then(() => {
								this.toast('Success', 'Gruppen er blevet slettet', true)
							})
							.catch((error) => {
								this.$swal.showValidationMessage(`Request failed: ${error.message}`)
							})
					}
				},
			})
		},
		getGroupInsurances(groupId) {
			return this.companyInsurances.filter((insurance) => insurance.groups.includes(groupId))
		},
		getGroupPensions(groupId) {
			return this.companyPensions.filter((pension) => pension.groups.includes(groupId))
		},
		async addGroup() {
			const swalConfig = {
				title: 'Indsæt navn for gruppen',
				html: `
					<input id="swal-input1" class="swal2-input" placeholder="Navn">
					<label><input type="checkbox" id="swal-input2"> Tillægsløn er dækningsgivende</label>
				`,
				preConfirm: () => {
					const groupName = document.getElementById('swal-input1').value
					const salaryIsBeneficial = document.getElementById('swal-input2').checked
					if (!groupName) {
						this.$swal.showValidationMessage('En gruppe skal have et navn')
						return false
					}
					return { groupName, salaryIsBeneficial }
				},
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ja opret!',
				showLoaderOnConfirm: true,
				preConfirm: async (formValues) => {
					try {
						var groupName = document.getElementById('swal-input1').value
						var salaryIsBeneficial = document.getElementById('swal-input2').checked
						const group = { name: groupName, salaryIsBeneficial: salaryIsBeneficial, id: uuidv4() }
						this.company.groups.push(group)
						const data = this.company
						const id = this.companyId
						return this.$store.dispatch('companyVuex/updateCompany', { data, id })
					} catch (error) {
						this.$swal.showValidationMessage(`Request failed: ${error.message}`)
					}
				},
			}
			const { value: formValues } = await this.$swal.fire(swalConfig)

			if (formValues) {
				this.toast('Success', 'En gruppe er blevet oprettet', true)
				this.clicked = false
			}
		},
		async openGroup(groupId) {
			if(!this.isAdmin){
				return
			}
			var group = this.groups.find((group) => group.id === groupId)

			const swalConfig = {
				title: 'Rediger gruppe',
				html: `
					<input id="swal-input1" class="swal2-input" placeholder="Navn" value="${group.name}">
					<br/>
					<br/>
					<label><input type="checkbox" id="swal-input2" ${group.salaryIsBeneficial ? 'checked' : ''}> Tillægsløn er dækningsgivende</label>
				`,
				preConfirm: () => {
					const groupName = document.getElementById('swal-input1').value
					const salaryIsBeneficial = document.getElementById('swal-input2').checked
					if (!groupName) {
						this.$swal.showValidationMessage('En gruppe skal have et navn')
						return false
					}
					return { groupName, salaryIsBeneficial }
				},
				showCancelButton: true,
				confirmButtonColor: 'green',
				cancelButtonColor: 'blue',
				confirmButtonText: 'Gem',
				showLoaderOnConfirm: true,
				cancelButtonText: 'Annuller',
				preConfirm: async (formValues) => {
					try {
						var groupName = document.getElementById('swal-input1').value
						var salaryIsBeneficial = document.getElementById('swal-input2').checked
						const group = { name: groupName, salaryIsBeneficial: salaryIsBeneficial, id: groupId }
						this.company.groups.splice(this.company.groups.indexOf(this.company.groups.find((group) => group.id == groupId)), 1, group)
						const data = this.company
						const id = this.companyId
						return this.$store.dispatch('companyVuex/updateCompany', { data, id })
					} catch (error) {
						this.$swal.showValidationMessage(`Request failed: ${error.message}`)
					}
				},
				showDenyButton: true,
				denyButtonText: 'Slet',
				denyButtonColor: '#d33',
				preDeny: async () => {
					try {
						await this.deleteGroup(groupId)
					} catch (error) {
						this.$swal.showValidationMessage(`Request failed: ${error.message}`)
					}
				},
			}

			const { value: formValues } = await this.$swal.fire(swalConfig)

			if (formValues) {
				this.toast('Success', 'Gruppen er blevet opdateret', true)
				this.clicked = false
			}
		},
	},
	computed: {
		companyId() {
			return this.$route.params.companyId
		},
		companyCvr() {
			return this.company.companyInfo.cvr
		},
		company() {
			var company = this.$store.getters['companyVuex/company']
			return company
		},
		isBusy() {
			return !this.$store.getters['companyVuex/isReady']
		},
		groups() {
			return this.$store.getters['companyVuex/groups']
		},
		companyInsurances() {
			return this.$store.getters['companyVuex/insurances']?.sort((a, b) => (a.insurance.name?.toLowerCase() > b.insurance.name?.toLowerCase() ? 1 : -1))
		},
		companyPensions() {
			return this.$store.getters['companyVuex/pensions']?.sort((a, b) => (a.pension.name?.toLowerCase() > b.pension.name?.toLowerCase() ? 1 : -1))
		},
		isAdmin() {
			return this.$store.getters['auth/isAdmin']
		},
	},
}
</script>

<style scoped>
.insurance-card {
	cursor: pointer;
	transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.insurance-card:hover {
	transform: translateY(-5px);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.card-footer a {
	text-decoration: none;
	color: #007bff; /* Bootstrap primary color */
}
</style>
